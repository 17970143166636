





















































































import { Vue, Component } from "vue-property-decorator";
import TextWrapperReplacer from "./company/robotStepperModules/TextWrapperReplacer.vue";
import PublicApisService from "~/services/publicApisService";
import { RuleValidationFunctionGeneric } from "~/models/types";

export type RegisterForQuarterlyUpdateModalResult = {
  type: "Cancel";
};

@Component({
  components: {
    TextWrapperReplacer,
  },
})
export default class RegisterForQuarterlyUpdateModal extends Vue {
  dialog = false;
  resolve: ((v: RegisterForQuarterlyUpdateModalResult) => void) | null = null;

  hasRegistered = false;
  isFromQrCode = false;

  open(v: {
    isFromQrCode: boolean;
  }): Promise<RegisterForQuarterlyUpdateModalResult> {
    this.dialog = true;
    this.isFromQrCode = v.isFromQrCode;

    this.$refs.editForm && this.$refs.editForm.resetValidation();

    return new Promise<RegisterForQuarterlyUpdateModalResult>(resolve => {
      this.resolve = resolve;
    });
  }

  $refs!: Vue["$refs"] & {
    editForm: any;
  };

  cancel() {
    if (this.isFromQrCode && !this.hasRegistered) {
      return;
    }
    if (this.resolve) {
      this.resolve({
        type: "Cancel",
      });
    }
    this.dialog = false;
  }

  email: string | null = null;

  get emailRules(): RuleValidationFunctionGeneric<string | null>[] {
    return [v => !!v || this.$t("Ange en e-postadress").toString()];
  }

  get emailOrEmpty() {
    return this.email ?? "";
  }

  save() {
    if (this.$refs.editForm.validate()) {
      if (this.resolve) {
        PublicApisService.upsertQuarterlyUpdateRegistration({
          dto: {
            email: this.email ?? "",
            registrationType: this.isFromQrCode ? "FromQrCode" : "FromBanner",
          },
        });
      }
      this.hasRegistered = true;
    }
  }
}
