import { render, staticRenderFns } from "./RegisterForQuarterlyUpdateModal.vue?vue&type=template&id=6f37fb4d&"
import script from "./RegisterForQuarterlyUpdateModal.vue?vue&type=script&lang=ts&"
export * from "./RegisterForQuarterlyUpdateModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./RegisterForQuarterlyUpdateModal.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5Ca%5C1%5Cs%5Ccomponents%5CRegisterForQuarterlyUpdateModal.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardText,VDialog,VForm,VIcon,VTextField})
