import { render, staticRenderFns } from "./QuarterlyUpdateBanner.vue?vue&type=template&id=773309a2&scoped=true&"
import script from "./QuarterlyUpdateBanner.vue?vue&type=script&lang=ts&"
export * from "./QuarterlyUpdateBanner.vue?vue&type=script&lang=ts&"
import style0 from "./QuarterlyUpdateBanner.vue?vue&type=style&index=0&id=773309a2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "773309a2",
  null
  
)

/* custom blocks */
import block0 from "./QuarterlyUpdateBanner.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5Ca%5C1%5Cs%5Ccomponents%5CQuarterlyUpdateBanner.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VIcon})
