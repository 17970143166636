



















import { Vue, Component } from "vue-property-decorator";
import CommonQuestion from "../common/CommonQuestion.vue";

@Component({
  components: {
    CommonQuestion,
  },
})
export default class CommonQuestionsTalent extends Vue {
  questions = [
    {
      question: this.$t("Vilken sorts jobb finns på Gritify?").toString(),
      isOpen: false,
      answer: this.$t(
        "På Gritify finns jobb för Ekonomer, Marknadsförare, Jurister, Ingenjörer samt alla inom Tech och HR. Det betyder att Gritify är perfekt för dig som vill ta steget från Managementkonsult till Strategichef, från Revisor till CFO, från IT-konsult till Lead Developer eller liknande. Ovan visas fler exempel på matchningar som gjorts på Gritify."
      ).toString(),
    },
    {
      question: this.$t(
        "Jag trivs bra på mitt nuvarande jobb. Varför ska jag skapa en profil?"
      ).toString(),
      isOpen: false,
      answer: this.$t(
        "Vad kul att du trivs på jobbet, du är i gott sällskap! Runt 60–70 % av alla med kvalificerade jobb trivs nämligen helt okej med sina befintliga jobb men har ändå en känsla vad nästa steg i karriären skulle kunna vara. Men vem orkar läsa intetsägande jobbannonser, uppdatera ”personligt brev” eller ta ”förutsättningslösa” samtal med rekryterare som inte har en aning om ens drömmar?! Genom att skapa en anonym profil på Gritify riskerar du aldrig att missa ett drömjobb. Inget spam och självklart kostnadsfritt."
      ).toString(),
    },
    {
      question: this.$t(
        "Kostar det något att ha en profil på Gritify?"
      ).toString(),
      isOpen: false,
      answer: this.$t(
        "Självklart inte. Gritify är och kommer alltid att vara helt kostnadsfritt för dig som talang och användare."
      ).toString(),
    },

    {
      question: this.$t("Varför är alla profiler anonyma?").toString(),
      isOpen: false,
      answer: this.$t(
        "Vi vill ge dig 100% kontroll över vilka som får ta del av personen bakom den anonyma profilen. Det ger dig möjlighet att löpande ta del av förfrågningar kring de mest intressanta jobben på marknaden, helt riskfritt. Dessutom ger anonyma profiler företag förutsättningar att objektivt utvärdera talangers kompetenser och erfarenheter."
      ).toString(),
    },
    {
      question: this.$t("Vad anonymiseras i min profil?").toString(),
      isOpen: false,
      answer: this.$t(
        "I din anonyma profil har vi plockat bort ditt namn, dina titlar och dina arbetsgivare. Väljer du att dela med dig av din fullständiga profil ser företag även detta. När du skapat din profil så kan du enkelt se hur både din anonyma och icke anonyma profil ser ut."
      ).toString(),
    },
    {
      question: this.$t(
        "Kan min nuvarande arbetsgivare hitta min profil?"
      ).toString(),
      isOpen: false,
      answer: this.$t(
        "Nej. Inte ens din anonyma profil är sökbar hos din befintliga arbetsgivare."
      ).toString(),
    },
    {
      question: this.$t("När och hur blir jag kontaktad om jobb?").toString(),
      isOpen: false,
      answer: this.$t(
        "När ett företag tycker din anonyma profil är en perfekt match i en specifik rekrytering kontaktar de dig och du aviseras via mail. Redan då vet du att tjänsten uppfyller 100% av dina krav kring lön, roll osv. Genom att följa länken i mailet kan du även ta del av ytterligare information kring företaget, tjänsten, chefen m.m. innan du väljer om du vill dela med dig av din identitet eller inte."
      ).toString(),
    },
    {
      question: this.$t(
        "Vad händer om jag tackar ja till en inbjudan?"
      ).toString(),
      isOpen: false,
      answer: this.$t(
        "Då får du och det rekryterande företaget möjlighet att inleda en dialog kring tjänsten som de kontaktat dig för."
      ).toString(),
    },
    {
      question: this.$t(
        "Vad händer om jag tackar nej till en inbjudan?"
      ).toString(),
      isOpen: false,
      answer: this.$t(
        "Då förblir du anonym, kan luta dig tillbaka och vänta in nästa förfrågan. Genom att besvara förfrågningar även om du tackar nej optimerar du även dina möjligheter att exponeras för mer relevanta tjänster framöver eftersom Gritify då lär sig ännu mer om dina unika preferenser."
      ).toString(),
    },
    {
      question: this.$t("Kan jag pausa eller ta bort min profil?").toString(),
      isOpen: false,
      answer: this.$t(
        "Ja, du kan enkelt pausa din profil så att du inte är sökbar för rekryterande företag. Givetvis kan du även ta bort din profil helt."
      ).toString(),
    },
  ];
}
